import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default CSS
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClients";

const Create = () => {
  const [id, setId] = useState("");
  const [timestamp, setTimeStamp] = useState(null);
  const [url, setUrl] = useState("");
  const [user_id, setuserID] = useState("");
  const [title, setTitle] = useState("");
  const [event_type, setEventType] = useState("Other");
  const dataset_id = "USER DATA";
  const [Reports, setReports] = useState("");
  const [formError, setFormError] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 30 });
  const [successMessage, setSuccessMessage] = useState(null)
  //const center = useState({ lat: 0, lng: 30 });

  const [geometry, setGeometry] = useState(null); // To store lat long geojson


  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setTimeStamp(date);
  };

  const containerStyle = {
    width: '400px',
    height: '400px'
  };

  const convertToGeoJSON = (lat, lng) => {
    return {
      type: "Point",
      coordinates: [lng, lat] // Note: GeoJSON typically uses [longitude, latitude] order
    };
  };

  const handleMapClick = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setGeometry(convertToGeoJSON(newLat, newLng));
};

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user_id || !timestamp || !title || !event_type) {
      setFormError("User ID, title, and event time are required.");
      setSuccessMessage("");
      return;
    }

    // Check if a marker position is set
    if (!geometry) {
      setFormError("Please click on the map to set a location.");
      setSuccessMessage("");
      return;
    }

    const newID = title + timestamp + Math.random()
    setId(newID)

    const { data, error } = await supabase
      .from("events")
      .insert([
        {
          title,
          id: newID,
          timestamp,
          event_type,
          dataset_id,
          geometry,
          Reports,
          user_id
        },
      ]);

    if (error) {
      console.log(error);
      console.log("the id is: " + id)
      setFormError("Please fill in all the fields correctly.");
      setSuccessMessage("");

    }
    else  {
      // Set success message and reset form fields
      setSuccessMessage("Data submitted successfully!");
      setTitle('');
      setId('');
      setTimeStamp(null);
      setGeometry(null);
      setUrl('');
      setReports('');
      //setUserId('');
      setFormError(null);
    }
  };
   
  return (
    
    <div className="page create">
      <div>
         {/*} <input id="pac-input" class="controls" type="text" placeholder="Jump to a location..." />*/}
          <div className="map-container">
            
          {/* Google Map */}
            <LoadScript
          googleMapsApiKey="AIzaSyCaaXIPeIzbudjtC1zfMy3Lhhyf9ZRSOsk" // Replace with your API key
        >
           

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={3}
            onClick={handleMapClick}
          >
            {/* Child components, like markers, info windows, etc. */}
            {markerPosition && <Marker position={markerPosition} />}
          </GoogleMap>
        </LoadScript>
        </div>
        {markerPosition && (
          <p className="lat-lng-display">
            Latitude: {markerPosition.lat.toFixed(4)}, Longitude: {markerPosition.lng.toFixed(4)}
          </p>
)}
    </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="title">User ID:</label>
        <input 
          type="text" 
          id="user_id"
          value={user_id}
          onChange={(e) => setuserID(e.target.value)}
        />

        <label htmlFor="title">Report Title:</label>
        <input 
          type="text" 
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <div>
          <p>Event time</p>
          <DatePicker
            selected={timestamp}
            onChange={handleDateChange}
            showTimeSelect
            dateFormat="yyyy-MM-dd hh:mm:ss" // Display date and time
          />
        </div>
        
        <label htmlFor="url">Source url:</label>
        <textarea 
          id="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <label htmlFor="eventType">Event Type:</label>
        <br></br>
        <select
          id="eventType"
          value={event_type}
          onChange={(e) => setEventType(e.target.value)}
        >
          <option value="Civil War">Civil War</option>
          <option value="Drought">Drought</option>
          <option value="Dust and Haze">Dust and Haze</option>
          <option value="Earthquake">Earthquake</option>
          <option value="Flood">Flood</option>
          <option value="Landslide">Landslide</option>
          <option value="Man-Made">Man-Made</option>
          <option value="Sea and Lake Ice">Sea and Lake Ice</option>
          <option value="Severe Storm">Severe Storm</option>
          <option value="Snow">Snow</option>
          <option value="Temperature Extreme">Temperature Extreme</option>
          <option value="Tropical Cyclone">Tropical Cyclone</option>
          <option value="Volcano">Volcano</option>
          <option value="Water Color">Water Color</option>
          <option value="Wildfire">Wildfire</option>
          <option value="Other">Other</option>
        </select>
        <br></br><br></br>

        <label htmlFor="reports">Description:</label>
        <textarea 
          id="reports"
          value={Reports}
          onChange={(e) => setReports(e.target.value)}
        />

       

        {/* Submit button */}
        <button>Upload</button>

        {formError && <p className="error">{formError}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
};

export default Create;



