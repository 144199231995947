import React, { useState } from "react";
import supabase from "../config/supabaseClients";
import * as XLSX from 'xlsx';
import "react-datepicker/dist/react-datepicker.css"; // Import the default CSS

const { data } = await supabase.auth.getSession()
const { session } = data
console.log({session}) // verify that session exists and the user is signed in.


const Create = () => {
  
    const [typeError, setTypeError] = useState(null);
    const [dataset_id, setDataset] = useState("USER DATA");
    const [authId, setauthId] = useState("");
    const [uploadErrors, setUploadErrors] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState([]);
    const [dateUpload, setDateUpload] = useState([]);



    
    //submit state
    const [uploadData, setData] = useState([]);

    //convert lat long to geojson
    const convertToGeoJSON = (lat, lng) => {
        return {
        type: "Point",
        coordinates: [lng, lat] // Note: GeoJSON typically uses [longitude, latitude] order
        };
    };

    //convert address to lat long
    const addressToLatLong = async (address) => {

        //create api url
        let cleanedAddress = address.replace(",", '');
        let addressArray = cleanedAddress.split(" ");
        let addressString = "";
        for (let a of addressArray) {addressString += "%20" + a;}
        let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${process.env.REACT_APP_GMAPS_KEY}`

        try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === 'OK') {
            const { lat, lng } = data.results[0].geometry.location;
            return { Latitude: lat, Longitude: lng };
        } else {
            // Handle case where geocoding was not successful
            console.error('Geocoding error:', data.status);
            return { Latitude: null, Longitude: null };
        }
    } catch (error) {
        console.error('Error fetching geocode data:', error);
        return { Latitude: null, Longitude: null };
    }


    }

    //convert excel date to Date
    const convertDateToJSDate = (excelDate) => {
    // Check if the date is already in ISO format (string)
    if (typeof excelDate === 'string') {
        const date = new Date(excelDate);
        return date.toISOString().split('T')[0];
    }
    // Check if the date is a JavaScript Date object
    else if (excelDate instanceof Date) {
        return excelDate.toISOString().split('T')[0];
    }
    // Assume it's an Excel serial date number
    else if (Number.isFinite(excelDate)) {
        const serial = Number(excelDate);
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date = new Date(utc_value * 1000);
        return date.toISOString().split('T')[0];
    }
    // If it's none of the above, return null or some default value
    setDateUpload("Timestamp upload failed for at least one row. Event saved as current date.")
    return Date();
};


    // onchange event
    const handleFile=(e)=>{
        let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
        let selectedFile = e.target.files[0];
        if(selectedFile){
            if(selectedFile&&fileTypes.includes(selectedFile.type)){
                setTypeError(null);
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0]);
                reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(sheet);
                setData(parsedData);
                };
                
            }
            else{
                setTypeError('Please select only Excel or CSV file types')
            }
        }
        else{
            console.log('Please select your file')
        }
    }
    const handleFileSubmit= async (e)=>{
        e.preventDefault();
        setUploadSuccess("Processing file")
        let errors = [];
        let count = 0;
        for (const row of uploadData) {
            count++;
            //set lat and long, return error if missing for a row
            let lat = null;
            let long =  null;
            let address = null;
            if ((row.lat || row.Lat || row.Latitude || row.latitude) & (row.long || row.Long || row.Longitude || row.longitude || row.lon || row.Lon || row.lng || row.Lng)){
                lat = row.lat ?? row.Lat ?? row.Latitude ?? row.latitude
                long = row.long ?? row.Long ?? row.Longitude ?? row.longitude ?? row.lon ?? row.Lon ?? row.lng ?? row.Lng
            }
            else if (row.Address || row.address){
                if(row.Address){
                    address = await addressToLatLong(row.Address);
                } else {
                    address = await addressToLatLong(row.address);
                }
                lat = address['Latitude'];
                long = address['Longitude'];
            } else {
                errors.push({ id: `Row ${count}`, error: 'Data contain an address or latitude and longitude.' });
                continue;
            }
            const geometry = convertToGeoJSON(lat, long);

            //handle times
            let timestamp = new Date();
            //convert excel date to Date
            if (row.timestamp){
                if (Number.isInteger(row.timestamp)) {
                    timestamp = convertDateToJSDate(row.timestamp) ?? null;
                }
            }
            // Set ID and other fields you handle separately
            const id = row.title + timestamp + count + Math.random();
            count++;
            const title = row.title ?? row.Title ?? '';
            const event_type = row.event_type || 'Other';
            const Reports = row.report || '';
            const user_id = authId;

            // Object to hold other data
            let properties = {};

            // Iterate through all keys in the row
            for (const key in row) {
                if (row.hasOwnProperty(key)) {
                    // Exclude keys that are handled separately
                    if (!['lat', 'long', 'title', 'event_type', 'report'].includes(key)) {
                        properties[key] = row[key];
                    }
                }
            }
            

            try {
                const { data, error } = await supabase
                    .from("events")
                    .insert([
                        {
                        title,
                        id,
                        timestamp,
                        event_type,
                        dataset_id,
                        geometry,
                        Reports,
                        user_id,
                        properties
                        },
                    ]);
                
                if (error) throw error;
            } catch (error) {
                errors.push({ id, error });
            }
            if (errors.length > 0) {
                setUploadErrors(errors);
                setUploadSuccess("Errors occurred during upload")
            } else {
                console.log("All data uploaded successfully");
                setUploadErrors([]); // Clear errors if upload is successful
        }
        }

        if (errors.length > 0) {
            console.error("Errors occurred during the upload:", errors);
        } else {
            console.log("All data uploaded successfully");
            setUploadSuccess("All data uploaded successfully")
        }
    }
   
  return (
    
    <div className="page create">  
    
        <form onSubmit={handleFileSubmit}>
            <label htmlFor="authID">User Authentication ID:</label>
            <input 
            type="text" 
            id="authID"
            value={authId}
            onChange={(e) => setauthId(e.target.value)}
            />
            </form>

        <form className="" onSubmit={handleFileSubmit}>
                <input type="file" className="" required onChange={handleFile}/>
                <p>
                Either an address column or latitude and longitude columns are required. <br />
                We recommend a Title column.<br />
                A "timestamp" column with dates in Excel date format will correctly date points. Otherwise, timestamp will be the upload time.
                </p>
                <button 
                    type="submit" 
                    className={` ${!authId.trim() ? 'button-disabled' : ''}`}
                    disabled={!authId.trim()}
                    >
                        UPLOAD
                    </button>
                {typeError&&(
                    <div className=''>{typeError}</div>
                )}
        </form>
        
        {uploadErrors.length > 0 && (
                    <div className="error-messages">
                        <h4>Upload Errors:</h4>
                        <ul>
                            {uploadErrors.map((error, index) => (
                                <li key={index}>{error.id}: {error.error.toString()}</li>
                            ))}
                        </ul>
                    </div>
                )}
        {uploadSuccess.length > 0 && (
                    <div className="success-messages">
                        <h4>Upload status:</h4>
                        <ul>
                            {uploadSuccess}
                        </ul>
                    </div>
                )}

    </div>

   
  )

  };
export default Create;



